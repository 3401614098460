


























































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ArrowLeftIcon, ThumbsUpIcon, PlusIcon } from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import MultiselectPicker from "../components/ui/Multiselect-Picker.vue";
import LeaseOperatorFreeModal from "../components/lease-detail/Lease-OperatorFreeModal.vue";
import PdfViewer from "../components/assets-detail/Pdf-Viewer.vue";

@Component({
  components: {
    ArrowLeftIcon,
    ThumbsUpIcon,
    Loader,
    MultiselectPicker,
    LeaseOperatorFreeModal,
    PdfViewer,
    PlusIcon,
  },
})
export default class NewLease extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  isEditModeActive = false;
  basicDetailData = "";

  $refs!: {
    machine_model: HTMLInputElement;
    fileInput1: HTMLInputElement;
  };
  mounted() {
    this.fetchAssetsList();
    this.fetchOperatorsList();

    const id = this.$router.currentRoute.params.id || null;
    if (id !== null) {
      this.isEditModeActive = true;
      this.isDataLoading = true;
      this.fetchAssetWithId(id);
    } else {
      this.basicDetailData = "true";
      setTimeout(() => {
        this.$refs.machine_model.focus();
      }, 10);
    }
  }

  onAddDocument() {
    this.$refs.fileInput1.click();
  }

  document: any = null;
  documentError = "";
  documentList: Array<{
    fileName: string;
  }> = [];
  isUploading = false;
  appDocumentUpload(e: any) {
    this.documentError = "";
    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type === "application/pdf" ||
        e.target.files[0].type === "application/doc"
      ) {
        this.document = e.target.files[0];
        if (this.documentList.length > 0) {
          this.documentList.pop();
        }
        this.documentList.push({
          fileName: e.target.files[0].name,
        });
      }
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "No file selected",
        type: "Danger",
      });
    }
  }

  async fetchAssetWithId(id: string) {
    try {
      this.isDataLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
        console.log(data);
        // Auto filling value for easy editing
        this.id = data.id;
        this.asset_id = data.assets[0].id;
        this.customer = data.customer;
        this.register_no = data.register_no;
        this.currency = data.currency;
        this.rental_start_date = data.rental_start_date;
        this.rental_end_date = data.rental_end_date;
        this.status = data.status;
        this.contract_value = data.contract_value;
        this.transportation_charges = data.transportation_charges;
        this.normal_amount = data.normal_amount;
        this.overtime_amount = data.overtime_amount;
        this.reimbursements = data.reimbursements;
        this.total_claimable_amount = data.total_claimable_amount;

        this.operator_id = [...data.operators];

        setTimeout(() => {
          this.$refs.machine_model.focus();
        }, 10);
      } else {
        this.basicDetailData = "";
      }
    } catch (e) {
      console.log(e);
      this.basicDetailData = "";
    } finally {
      this.isDataLoading = false;
    }
  }

  // variables
  id = "";

  assetsList: any = [];
  asset_id: any = "";
  asset_idError = "";

  customer = "";
  customerError = "";

  register_no = "";
  register_noError = "";

  currency = "";
  currencyError = "";

  rental_start_date = "";
  rental_start_dateError = "";

  rental_end_date = "";
  rental_end_dateError = "";

  leaseType = "wet_lease";

  status = "active";
  statusError = "";

  contract_value = "";
  contract_valueError = "";

  transportation_charges = "";
  transportation_chargesError = "";

  normal_amount = "";
  normal_amountError = "";

  overtime_amount = "";
  overtime_amountError = "";

  reimbursements = "";
  reimbursementsError = "";

  total_claimable_amount = "";
  total_claimable_amountError = "";

  @Watch("customer") onwatchcustomer() {
    this.customerError = "";
  }
  @Watch("register_no") onwatchregister_no() {
    this.register_noError = "";
  }
  onRentalStartDateChanged(e: any) {
    this.rental_start_date = e.target.value;
    this.rental_start_dateError = "";
    if (
      new Date(this.rental_start_date) <= new Date(Date.now()) &&
      new Date(Date.now()) <= new Date(this.rental_end_date)
    ) {
      this.status = "active";
    } else if (new Date(this.rental_start_date) >= new Date(Date.now())) {
      this.status = "inactive";
    }
  }
  onRentalEndDateChanged(e: any) {
    this.rental_end_date = e.target.value;
    this.rental_end_dateError = "";
    if (
      new Date(this.rental_start_date) <= new Date(Date.now()) &&
      new Date(Date.now()) <= new Date(this.rental_end_date)
    ) {
      this.status = "active";
    } else if (new Date(this.rental_end_date) <= new Date(Date.now())) {
      this.status = "lease_completed";
    }
  }
  @Watch("currency") onwatchcurrency() {
    this.currencyError = "";
  }
  @Watch("contract_value") onwatchcontract_Value() {
    this.contract_valueError = "";
  }
  @Watch("transportation_charges") onwatchtransportation_charges() {
    this.transportation_chargesError = "";
  }
  @Watch("normal_amount") onwatchnormal_amount() {
    this.normal_amountError = "";
  }
  @Watch("overtime_amount") onwatchovertime_amount() {
    this.overtime_amountError = "";
  }
  @Watch("reimbursements") onwatchreimbursements() {
    this.reimbursementsError = "";
  }
  @Watch("total_claimable_amount") onwatchtotal_claimable_amount() {
    this.total_claimable_amountError = "";
  }
  @Watch("status") onwatchstatus() {
    this.statusError = "";
  }

  onSelectedAssetsChanged(value, id) {
    this.asset_id = [...value];
  }

  operator_id: any = [];
  operator_idError = "";
  onSelectedOperatorChanged(value, id) {
    this.checkFreeOperatorWithId(value[value.length - 1]);
    this.operator_id = [...value];
  }

  isDataLoading = false;
  async fetchAssetsList() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease/assets/free`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        this.assetsList = (await response.json()).map((x) => ({
          ...x,
          brandModel: x.brand + " " + x.model
        }));
        // const id: any = this.$router.currentRoute.query.id || null;
        // if (id != null) {
        //   this.asset_id = id;
        // } else {
        //   this.asset_id = this.assetsList[0];
        // }
        setTimeout(() => {
          this.$refs.machine_model.focus();
        }, 10);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isDataLoading = false;
    }
  }

  operatorList: any = [];
  async fetchOperatorsList() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        this.operatorList = await response.json();
        setTimeout(() => {
          this.$refs.machine_model.focus();
        }, 10);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isDataLoading = false;
    }
  }

  async checkFreeOperatorWithId(data: any) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease/operator/free/${data.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const isFree = await response.json();
        if (!isFree.free) {
          this.showFreeOperatorModal(data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  selectedLeaseOperatorFreeModalData: any = "";
  showLeaseOperatorFreeModal = false;
  showFreeOperatorModal(data) {
    if (this.operatorList.length > 0) {
      this.selectedLeaseOperatorFreeModalData = { ...data };
    }
    this.showLeaseOperatorFreeModal = true;
  }

  onSubmitLeaseOperatorFreeModal() {
    this.onHideLeaseOperatorFreeModal();
    this.operator_id.pop();
  }

  onHideLeaseOperatorFreeModal() {
    this.showLeaseOperatorFreeModal = false;
  }

  isLoading = false;
  async onSubmitMaintenanceData() {
    try {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("asset_ids", JSON.stringify([this.asset_id]));
      formData.append(
        "operator_ids",
        JSON.stringify(this.operator_id.map((x) => x.id))
      );
      formData.append("customer", this.customer);
      formData.append("register_no", this.register_no);
      formData.append("currency", this.currency);
      formData.append("rental_start_date", this.rental_start_date);
      formData.append("rental_end_date", this.rental_end_date);
      formData.append("status", this.status);
      formData.append("contract_value", this.contract_value);
      formData.append("transportation_charges", this.transportation_charges);
      formData.append("normal_amount", this.normal_amount);
      formData.append("overtime_amount", this.overtime_amount);
      formData.append("reimbursements", this.reimbursements);
      formData.append("total_claimable_amount", this.total_claimable_amount);
      formData.append("files", this.document);
      const data = {
        asset_ids: [this.asset_id],
        operator_ids: this.operator_id.map((x) => x.id),
        customer: this.customer,
        register_no: this.register_no,
        currency: this.currency,
        rental_start_date: this.rental_start_date,
        rental_end_date: this.rental_end_date,
        status: this.status,
        contract_value: this.contract_value,
        transportation_charges: this.transportation_charges,
        normal_amount: this.normal_amount,
        overtime_amount: this.overtime_amount,
        reimbursements: this.reimbursements,
        total_claimable_amount: this.total_claimable_amount,
      };

      let response;
      if (this.isEditModeActive) {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease/${this.id}`,
          {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
        );
      } else {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/lease`,
          {
            method: "POST",
            credentials: "include",
            // headers: { "Content-Type": "application/json" },
            body: formData,
          }
        );
      }

      if (response.status == 200) {
        if (this.isEditModeActive) {
          this.$store.commit("showToastMessage", {
            boldText: "Success!",
            text: "Lease edited successfully",
            type: "Success",
          });
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Success!",
            text: "Lease added successfully",
            type: "Success",
          });
        }
        this.$router.replace({ path: "/asset-tracker/lease" });
      } else if (response.status == 400) {
        const error = await response.json();
        switch (error[0].path[0]) {
          case "customer":
            this.customerError = error[0].message;
            break;
          case "register_no":
            this.register_noError = error[0].message;
            break;
          case "currency":
            this.currencyError = error[0].message;
            break;
          case "rental_start_date":
            this.rental_start_dateError = error[0].message;
            break;
          case "rental_end_date":
            this.rental_end_dateError = error[0].message;
            break;
          case "status":
            this.statusError = error[0].message;
            break;
          case "contract_value":
            this.contract_valueError = error[0].message;
            break;
          case "transportation_charges":
            this.transportation_chargesError = error[0].message;
            break;
          case "normal_amount":
            this.normal_amountError = error[0].message;
            break;
          case "overtime_amount":
            this.overtime_amountError = error[0].message;
            break;
          case "reimbursements":
            this.reimbursementsError = error[0].message;
            break;
          case "total_claimable_amount":
            this.total_claimable_amountError = error[0].message;
            break;
          default:
            break;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }
}
