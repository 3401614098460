<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      :label="label"
      track-by="id"
      :multiple="true"
      class="mt-2"
      @input="onInputChange"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: { Multiselect },
  props: {
    label: String,
    options: [],
    onChange: Function,
    selectedValues: [],
  },
  data() {
    return { selected: null };
  },
  methods: {
    onInputChange: function (value, id) {
      this.onChange(value, id);
    },
  },
  watch: {
    selectedValues: function () {
      this.selected = [...this.selectedValues];
    },
  },
};
</script>

<style>
.dark .multiselect__tags {
  background: #232a3b !important;
}

.dark .multiselect__input {
  background: transparent !important;
}

.multiselect__select {
  display: none;
}
</style>